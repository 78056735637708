/* global _ angular */

/** @ngInject */
function ToolShowController (
  $rootScope,
  FormlyValidations,
  $translate,
  $scope,
  $state,
  $stateParams,
  $mdToast,
  $mdDialog,
  IntercomService,
  Tool,
  $timeout,
  ResolvedTool,
  FormlyHelper,
  ResolvedGroups,
  ErrorHandlerService,
  PermissionUtils
) {
  $scope.PermissionUtils = PermissionUtils
  $scope.Tool = Tool
  $scope.editLanguage = $rootScope.appSettings.defaultContentLanguage

  const separateToolSerialsWithGroups =
    $rootScope.appSettings.separateToolSerialsWithGroups || false

  const serialsFields = [
    {
      key: 'serial',
      type: 'input',
      className: 'flex-30 margin-5',
      templateOptions: {
        required: true,
        type: 'text',
        label: $translate.instant('Tool.SERIAL')
      }
    },
    // Add new files field
    {
      key: 'resourceIds',
      type: 'upload',
      className: 'flex-30 margin-5',
      templateOptions: {
        required: false,
        caption: $translate.instant('Tool.Attachment'),
        mode: 'multiple',
        onFileSelect: function (result) {}
      }
    },
    {
      key: 'expireDate',
      type: 'datePicker',
      className: 'flex-30 margin-5',
      templateOptions: {
        required: true,
        label: $translate.instant('Tool.expireDate')
      }
    },
    {
      key: 'active',
      type: 'switch',
      className: 'flex-30 margin-5',
      templateOptions: {
        label: $translate.instant('Tool.active')
      }
    }
  ]

  if (separateToolSerialsWithGroups) {
    serialsFields.splice(1, 0, {
      key: 'groupIds',
      type: 'selectWithSearch',
      className: 'flex-30 margin-5',
      templateOptions: {
        multiple: true,
        label: $translate.instant('Tool.GROUPS'),
        options: ResolvedGroups
      }
    })
  }
  const staticFields = [
    // NAME
    {
      key: 'name',
      type: 'input',
      className: 'layout-row',
      validators: {
        noComma: FormlyValidations.noComma
      },
      templateOptions: {
        type: 'text',
        focus: true,
        required: true,
        label: $translate.instant('Tool.name'),
        placeholder: $translate.instant('Tool.name'),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    // SKU

    {
      key: 'sku',
      type: 'input',
      className: 'layout-row',
      validators: {
        noComma: FormlyValidations.noComma
      },
      templateOptions: {
        type: 'text',
        required: false,
        label: $translate.instant('Tool.sku'),
        placeholder: $translate.instant('Tool.sku'),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    // DESCRIPTION
    {
      key: 'description',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        label: $translate.instant('Tool.description'),
        placeholder: $translate.instant('Tool.description'),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    // RESOURCE_ID
    {
      key: 'resourceId',
      type: 'upload',
      className: 'layout-column',
      templateOptions: {
        required: false,
        caption: $translate.instant('Tool.Attachment'),
        keyIs: 'id',
        object: 'image_object',
        mode: 'full',
        onFileSelect: function (result) {}
      }
    },
    // IS_SERIAL
    {
      key: 'isSerial',
      type: 'switch',
      className: 'layout-row',
      templateOptions: {
        label: $translate.instant('Tool.IS_SERIAL'),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    // SERIALS
    {
      key: 'serials',
      type: 'repeatingTemplate',
      templateOptions: {
        layout: 'row',
        title: $translate.instant('Tool.TOOL_SERIALS'),
        btnText: $translate.instant('Tool.ADD_NEW_SERIAL'),
        hideMovementButtons: true,
        addParameters: () => ({
          serial: '',
          expireDate: new Date(),
          active: true,
          isExpire: false,
          files: [] // Add files array initialization
        }),
        onChange: function () {},
        fields: serialsFields
      },
      hideExpression: function ($viewValue, $modelValue, scope) {
        return !scope.model.isSerial
      }
    }
  ]

  const errorHandler = function errorHandler (res) {
    $scope.translate($rootScope.appSettings.defaultContentLanguage)
    $timeout(
      () => {
        ErrorHandlerService.formlyErrorHandler(
          res,
          $scope.toolFields,
          $scope.toolForm
        )
      },
      0,
      false
    )
  }

  const initScreen = function initScreen () {
    $scope.tool = ResolvedTool || {
      name: '',
      description: '',
      isSerial: false,
      serials: []
    }
    $scope.deletedTool = $scope.tool.deletedAt || null
    $scope.editMode = !_.isNil($stateParams.id)
    $scope.toolFields = FormlyHelper.buildFields(staticFields, Tool)
  }

  initScreen()

  $scope.deleteTool = function deleteTool () {
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title($translate.instant('Tool.deleteConfirm'))
          .targetEvent()
          .clickOutsideToClose(true)
          .multiple(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          // delete
          Tool.destroyById({ id: $stateParams.id })
            .$promise.then(() => {
              $mdToast.show(
                $mdToast.nextplus({
                  position: $rootScope.toastLocation,
                  parent: 'body',
                  theme: 'success-toast',
                  hideDelay: 3000
                })
              )
              $mdToast.updateTextContent($translate.instant('Tool.toolDeleted'))
              $state.go('app.tool.list')
            })
            .catch(err => {
              console.error(err)
              $rootScope.showErrorToast('NP-4001')
            })
        },
        function () {}
      )
  }

  $scope.formValid = () => {
    $scope.toolForm.$pristine = false
  }

  $scope.save = async function save () {
    $rootScope.loadingProgress = true
    const serials = new Set($scope.tool.serials.map(s => s.serial))
    if ($scope.tool.serials.length !== serials.size) {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'body',
          theme: 'error-toast',
          hideDelay: 3000
        })
      )
      $mdToast.updateTextContent($translate.instant('Tool.DUPLICATE_SERIAL'))
      $rootScope.loadingProgress = false
      return
    }
    try {
      const toolObject = _.omit($scope.tool, ['image_object', 'id'])
      if ($scope.editMode) {
        await Tool.prototype$patchAttributes({ id: $scope.tool.id }, toolObject)
          .$promise
      } else {
        await Tool.create(toolObject).$promise
        IntercomService.trackEvent('tool-create')
      }
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'body',
          theme: 'success-toast',
          hideDelay: 3000
        })
      )
      $mdToast.updateTextContent($translate.instant('Tool.toolSaved'))
      $state.go('app.tool.list')
    } catch (err) {
      console.error(err)
      errorHandler(err)
    } finally {
      $rootScope.loadingProgress = false
    }
  }

  $scope.headerOptions = {
    icon: 'icon-basket',
    postTitleHtml: $scope.editMode
      ? `<div layout="row" layout-align="start center">
          <md-button ng-if="!deletedTool" class="md-raised md-warn" ng-disabled="!PermissionUtils.isPermit('Tool','deleteById')" ng-click="deleteTool()">
            <md-icon md-font-icon="icon icon-delete" class="s20" style="color:white !important;"></md-icon>
            <span translate="BUTTONS.DELETE"></span>
          </md-button>
        </div>`
      : '',
    template: require('app/templates/headers/simple.html'),
    title: $scope.editMode
      ? $translate.instant('Tool.editTool') + ' ' + ResolvedTool.name
      : $translate.instant('Tool.createTool'),
    fabButton: {}
  }
}
module.exports = ToolShowController
